import React, { lazy, useContext, useEffect } from 'react';
import {
	useRoutes,
	Navigate,
	useNavigate,
	useLocation,
	useSearchParams,
	useParams,
} from 'react-router-dom';
import { LocalStorage } from 'utils/localStorage';
import AdvertisementsProvider from 'contexts/AdvertisementsProvider';
import SignupProvider from 'screens/Signup/SignupProvider';
import useUserStore from 'stores/UserStore';
import useEventsStore from 'stores/EventsStore';
import useAdvertismentsStore from 'stores/AdvertisementsStore';
import useAuthStore from 'stores/AuthStore';
import useAccountsStore from 'stores/AccountsStore';
import { NotifySuccess, NotifyError } from "utils/notifications";
import useGlobalStore from 'stores/GlobalStore';
import useAppNavigate from "hooks/useAppNavigate";

const Home = lazy(() => import('screens/Home/Home'));
const Login = lazy(() => import('screens/Login/Login'));
const Signup = lazy(() => import('screens/Signup/Signup'));
const Services = lazy(() => import('screens/Services/Services'));
const Venues = lazy(() => import('screens/Venues/Venues'));
const EventDetails = lazy(() => import('screens/EventDetails/EventDetails'));
const ServiceDetails = lazy(() => import('screens/ServiceDetails/ServiceDetails'));
const Accounts = lazy(() => import('screens/Accounts/Accounts'));
const AccountDetails = lazy(() => import('screens/AccountDetails/AccountDetails'));
const Settings = lazy(() => import('screens/Settings/Settings'));
const Support = lazy(() => import('screens/Support/Support'));
const Resources = lazy(() => import('screens/Resources/Resources'));
const Advertisements = lazy(() => import('screens/Advertisements/Advertisements'));
const Salto = lazy(() => import('screens/Salto/Salto'));
const Customers = lazy(() => import('screens/Customers/Customers'));
const CustomerDetails = lazy(() => import('screens/Customer/Customer'));
const Memberships = lazy(() => import('screens/Membership/Memberships'));
const SALTOAccountDetails = lazy(() => import('screens/Salto/SaltoAccountDetails'));
const ResetPassword = lazy(() => import('screens/ResetPassword/ResetPassword'));
const Error404 = lazy(() => import('screens/Error404/Error404'));
const Dashboard = lazy(() => import('screens/Dashboard/Dashboard'));

function Routes() {
	const navigate = useAppNavigate();
	const location = useLocation();
	const UserStore = useUserStore((s) => s);
	const AuthStore = useAuthStore();
	const EventsStore = useEventsStore();

	const AdvertisementsStore = useAdvertismentsStore();
	const AccountsStore = useAccountsStore()
	const rootRoutePath = location.pathname.split('/')[1];
	const userPermissions = UserStore.user?.role_details?.permissions || [];


	const isAccessibleRoute = () => {
		return (
			userPermissions.includes(rootRoutePath) ||
			((rootRoutePath == 'resources' || rootRoutePath == 'support')) || true
		);
	};

	useEffect(() => {
		if (!AuthStore.isLogin() && location.pathname != "/signup" && location.pathname != "/resetpassword") {
			navigate('/login');
			LocalStorage.clear();
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [AuthStore.isLogin()]);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const urlAction = urlParams.get('action');
		switch (urlAction) {
			case 'stripe_oauth_connect':
				const isSuccess = urlParams.get('oauth') == 1
				const messageData = {
					msgTitle: isSuccess ? "Success" : "Error",
					msgBody: isSuccess ? "Successfully connected to Stripe" : "Please contact support"
				}
				if (isSuccess) {
					UserStore.updateUser({
						is_business_stripe_connected: true
					})
				}
				const tokenisedURL = window.location.href.split("?");
				const newURL = tokenisedURL[0]
				NotifySuccess({ title: messageData.msgTitle, message: messageData.msgBody })
				window.history.replaceState({}, document.title, newURL);
				break;
		}
	}, [])

	useEffect(() => {
		if (AuthStore.isLogin()) {
			const init = async () => {
				if (!isAccessibleRoute() && !AuthStore.isLogin()) {
					navigate('/404');
					return;
				}
				else if (!isAccessibleRoute() && AuthStore.isLogin()) {
					navigate('/settings');
					return;
				}

				const businessId = UserStore.user.business_id;
				switch (rootRoutePath) {
					case 'accounts':
						if (AccountsStore.accounts?.length <= 0) {
							AccountsStore.loadAccounts({ businessId })
						}
						break;
					case 'settings':
						break;
					case 'support':
						break;
					case 'resources':
						break;
				}
			};
			init();
		}
	}, [location.pathname]);

	const routes = useRoutes([
		// {
		// 	path: '/',
		// 	element: <Navigate to="/events" replace={true} />,
		// },
		// { path: '/home', element: <Events /> },
		{ path: '/login', element: <Login /> },
		{ path: '/resetpassword', element: <ResetPassword /> },
		{
			path: '/signup',
			element: (
				<SignupProvider>
					<Signup />
				</SignupProvider>
			),
		},
		{ path: '/dashboard', element: <Dashboard /> },
		{
			path: '/services',
			children: [
				{
					path: '',
					element: <Services />,
				},
				{
					path: '/services/:id',
					element: <ServiceDetails />,
				},
			],
		},
		{
			path: '/accounts',
			children: [
				{
					path: '',
					element: <Accounts />,
				},

				{
					path: '/accounts/:id',
					element: <AccountDetails />,
				},
			],
		},
		{ path: '/settings', element: <Settings /> },
		{ path: '/support', element: <Support /> },
		{ path: '/resources', element: <Resources /> },
		{
			path: '/salto',
			children: [
				{
					path: '',
					element: <Salto />,
				},
				{
					path: '/salto/:id',
					element: <SALTOAccountDetails />,
				},
			],
		},
		{
			path: '/customers',
			children: [
				{
					path: '',
					element: <Customers />,
				},
				{
					path: '/customers/:id',
					element: <CustomerDetails />,
				},
			],
		},
		{
			path: '/memberships',
			children: [
				{
					path: '',
					element: <Memberships />,
				}
			],
		},
		// {
		// 	path: '/advertisements',
		// 	element: (
		// 		<AdvertisementsProvider>
		// 			<Advertisements />
		// 		</AdvertisementsProvider>
		// 	),
		// },
		{ path: '/404', element: <Error404 /> },
		{ path: '*', element: <Navigate to="/404" /> },
	]);

	return <>{routes}</>;
}
export default Routes;
