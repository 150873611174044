/**
 * Contains the api for the accounts of business
 */
import { API } from 'utils/axios';
import { DEFAULT_USER_META } from 'utils/constants/core.js';
const meta = DEFAULT_USER_META;

export const getBusinessAccounts = async ({ business_id }) => {
	const command = 'get_business_accounts';
	const data = {
		cmd: command,
		business_id,
	};

	return await API({ data });
};

export const getBusinessCustomers = async () => {
	const command = 'get_business_customers';
	const data = {
		cmd: command,
	};

	return await API({ data });
};

export const getMemberships = async () => {
	const command = 'get_business_memberships';
	const data = {
		cmd: command,
	};

	return await API({ data });
};

export const createCustomer = async ({ name, email, phone, address, membership, emergencyName, emergencyPhone, emergencyEmail, password, gender, dob }) => {
	const command = 'create-customer';
	const data = {
		cmd: command,
		name, email, phone, address, membership, emergencyName, emergencyPhone, emergencyEmail, password, meta, gender, dob
	};

	return await API({ data });
}

export const updateCustomer = async ({ id, name, email, phone, address, membership, emergencyName, emergencyPhone, emergencyEmail, gender, dob }) => {
	const command = 'update-customer';
	const data = {
		cmd: command,
		id, name, email, phone, address, membership, emergencyName, emergencyPhone, emergencyEmail, meta, gender, dob
	};

	return await API({ data });
}

export const getBusinessAccount = async ({ acc_id }) => {
	const command = 'get_business_account';
	const data = {
		cmd: command,
		id: acc_id,
	};

	return await API({ data });
};

export const getCustomer = async ({ id }) => {
	const command = 'get_customer';
	const data = {
		cmd: command,
		id: id,
	};

	return await API({ data });
};

export const deleteAccount = async ({ acc_id }) => {
	const command = 'delete-business-account';
	const data = {
		cmd: command,
		id: acc_id,
	};

	return await API({ data });
}
/**
 * 
 * @param {object} userData {name,email,phone,address,password,role,business_id,account_type}  
 * @param {object} emergencyContactData {name, email, phone, relationship, address}  
 * @returns 
 */
export const createBusinessAccount = async ({
	userData,
	emergencyContactData,
	userImage
}) => {
	const command = 'handle_business_account';
	const data = {
		cmd: command,
		dbofields: userData,
		obj_id: -1,
		image: userImage
	};

	if (emergencyContactData) data['emergency'] = emergencyContactData;
	return await API({ data });
};
export const updateBusinessAccount = async ({
	name, email, phone, address,
	uid,
	userImage = false
}) => {
	const command = 'handle_business_account';
	const data = {
		cmd: command,
		dbofields: {
			name, email, phone, address,
		},
		obj_id: uid,
	};
	if (userImage) {
		data["image"] = userImage
	}

	return await API({ data });
};

/**
 * 
 * @param {object} emergencyContactData { name,email,phone,address,relationship} 
 * @param {string|number} uid  
 */
export const updateAccountEmergencyContact = async ({
	emergencyContactData,
	uid,
	obj_id = -1
}) => {
	const command = 'update_account_emergency_contact';
	const data = {
		cmd: command,
		dbofields: emergencyContactData,
		vendor_user_id: uid,
		obj_id
	};

	return await API({ data });
};
export const updateBusinessAccountPassword = async ({ uid, password, login_key }) => {
	const command = 'update_business_account_password';
	const data = {
		cmd: command,
		uid,
		password,
		login_key
	};


	return await API({ data });
};

export const updateCustomerPassword = async ({ id, password }) => {
	const command = 'update_customer_password';
	const data = {
		cmd: command,
		id,
		password
	};


	return await API({ data });
};

export const deleteCustomer = async ({ id }) => {
	const command = 'delete_customer';
	const data = {
		cmd: command,
		id
	}

	return await API({ data });
}